export const prompts = (t = () => {}) => {
	const loginTranslationKey = 'common:loginLoading_prompt';
	const signupTranslationKey = 'signup:signupLoading_prompt';
	const appRedirectKey = 'signup:redirect_to_app';

	const generatePrompts = (translationKey, count) => {
		const promptsArray = Array.from({ length: count }, (_, index) =>
			t(`${translationKey}_${index + 1}`),
		);

		return promptsArray;
	};

	return {
		login: generatePrompts(loginTranslationKey, 5),
		signup: generatePrompts(signupTranslationKey, 9),
		redirect_to_app: generatePrompts(appRedirectKey, 1),
	};
};
