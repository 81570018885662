import { Popover, Button, cl } from '@cogoport/components';
import { IcMAgentManagement } from '@cogoport/icons-react';
import { useTranslation } from 'next-i18next';

import { Image, Link } from '@/temp/next';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import HelpCard from '../HelpCard';

import styles from './styles.module.css';

function LayoutLogo({ src = '' }) {
	const { t } = useTranslation(['common']);
	const translationKey = 'common:helpButton';

	return (
		<div className={styles.header}>
			<Link href="/welcome">
				<Image
					src={GLOBAL_CONSTANTS.image_urls.cogoport_icon}
					alt={t(`${translationKey}_cogoport`)}
					width={120}
					height={36}
				/>
			</Link>

			<div
				className={cl`${styles.help_mobile} ${
					src === 'forgot_password' ? styles.hide : ''
				}`}
			>
				<Popover placement="bottom" render={<HelpCard />}>
					<Button size="md" themeType="accent" type="button">
						<IcMAgentManagement style={{ marginRight: '4px' }} />
						{t(`${translationKey}_label`)}
					</Button>
				</Popover>
			</div>
		</div>
	);
}

export default LayoutLogo;
