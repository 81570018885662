import { useEffect, useRef } from 'react';

import { useTranslation } from 'next-i18next';
import { Button } from '@cogoport/components';
import {
	Container,
	ResendOtpContainer,
	TimerText,
	InvalidOTPContainer,
} from './styles';

import OtpInput from './components/OtpInput';
import useTimer from './hooks/useTimer';

function OTPLayout({
	otpLength,
	setOtpValue = () => {},
	loading = false,
	sendOtp = () => {},
	invalidOTPError,
}) {
	const { t } = useTranslation(['common']);

	const useImperativeHandleRef = useRef({});

	const timer = useTimer({ durationInSeconds: 30 });

	useEffect(() => timer.start(), []);

	return (
		<Container>
			<OtpInput
				otpLength={otpLength}
				inputSize="lg"
				onChange={(value) => {
					setOtpValue(value.length === otpLength ? `${value}` : '');
				}}
				ref={useImperativeHandleRef}
			/>

			{invalidOTPError ? (
				<InvalidOTPContainer>{invalidOTPError || null}</InvalidOTPContainer>
			) : null}

			<ResendOtpContainer>
				<TimerText>
					{timer.minutes} : {timer.seconds}
				</TimerText>

				<Button
					role="presentation"
					type="button"
					onClick={() => {
						sendOtp({ timer });

						useImperativeHandleRef.current?.resetOtp();
					}}
					size="lg"
					themeType="linkUi"
					disabled={timer.isTimeRemaining || loading}
				>
					{t('common:otpLayout_resendOtpButton_label')}
				</Button>
			</ResendOtpContainer>
		</Container>
	);
}

export default OTPLayout;
