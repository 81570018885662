import styled from '@cogoport/front/styled';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ResendOtpContainer = styled.div`
	margin-top: 8px;

	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const TimerText = styled.div`
	font-size: 18px;
	font-weight: bold;
	color: #81c0af;
`;

export const InvalidOTPContainer = styled.div`
	color: red;
	margin-top: 10px;
	width: 100%;
`;
