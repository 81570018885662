import { IcMCall, IcMEmail, IcMWhatsapp } from '@cogoport/icons-react';
import { useTranslation } from 'next-i18next';

import GLOBAL_CONSTANTS from '@/constants/globals.json';
import styles from './styles.module.css';

const NAME = GLOBAL_CONSTANTS.onboarding_specialist?.name;
const PHONE_NUMBER = GLOBAL_CONSTANTS.onboarding_specialist?.phone_number;
const EMAIL_ID = GLOBAL_CONSTANTS.onboarding_specialist?.email_id;

function HelpCard() {
	const { t } = useTranslation(['common']);
	const translationKey = 'common:helpButton';

	return (
		<div className={styles.help_card}>
			<span>{t(`${translationKey}_title`)}</span>

			<hr className={styles.line} />

			<h4>{NAME}</h4>

			<div className={styles.links}>
				<IcMEmail style={{ marginRight: '8px' }} />

				{t(`${translationKey}_email`)}

				<a href={`mailto:${EMAIL_ID}`} className={styles.link_value}>
					{EMAIL_ID}
				</a>
			</div>

			<div className={styles.links}>
				<IcMCall style={{ marginRight: '8px' }} />

				{t(`${translationKey}_mobile`)}

				<a href={`tel:${PHONE_NUMBER}`} className={styles.link_value}>
					{PHONE_NUMBER}
				</a>
			</div>

			<div className={styles.links}>
				<IcMWhatsapp style={{ marginRight: '8px' }} />

				{t(`${translationKey}_whatsapp`)}

				<a
					href="https://api.whatsapp.com/message/N4CI7UNDFE2ZM1?autoload=1&app_absent=0"
					className={styles.link_value}
				>
					{PHONE_NUMBER}
				</a>
			</div>
		</div>
	);
}

export default HelpCard;
