import { memo, forwardRef } from 'react';
import { Input } from '@cogoport/front/components';

import { useTranslation } from 'next-i18next';

import useOtpInput from './hooks/useOtpInput';
import { Container, InputItem, Lebal } from './styles';

function OtpInput(props, ref) {
	const { otpLength = 0, inputSize = 'md', onChange = () => {} } = props;
	const { t } = useTranslation(['login']);
	const {
		values = {},
		otpContainerRef = null,
		otpInputElementsRef = null,
		handleChange = () => {},
	} = useOtpInput({
		otpLength,
		onChange,
		ref,
	});

	return (
		<>
			<Lebal>
				{' '}
				{t('signup:rightPanel_verification_mobile_texts_enter_otp_label')}{' '}
			</Lebal>
			<Container ref={otpContainerRef}>
				{Object.keys(values).map((key, index) => (
					<InputItem key={key} marginLeft={index === 0 ? 0 : '16px'}>
						<Input
							size={inputSize}
							value={values[key]}
							onChange={handleChange(index)}
							ref={(element) => {
								otpInputElementsRef.current[index] = element;
							}}
						/>
					</InputItem>
				))}
			</Container>
		</>
	);
}

export default memo(forwardRef(OtpInput));
