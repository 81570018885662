// TODO remove this component

import { Toast } from '@cogoport/components';
import { isEmpty, getApiErrorString } from '@cogoport/front/utils';

const showErrorsInToast = (messages) => {
	if (isEmpty(messages)) {
		return;
	}

	Toast.error(getApiErrorString(messages || {}), {
		autoClose: 6000,
	});
};

export default showErrorsInToast;
